<template>
  <div>
    <div class="row p-l-15 p-b-10">
      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>{{$t('계통명')}}</span>
        </div>
        <div class="p-l-5">
          <valid-input
						ref="keyword"
						:classList="'form-control'"
						@changeMethod="onSearch"
						:inputType="'text'"
						:inputValue.sync="systemCodeName"
						:rules="rules.EQUIP_SYSTEM_SEARCH_NAME_RULE"
						:NonStatus="true"
					></valid-input>
        </div>
      </div>
      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-15">
          <span>{{$t('사용여부')}}</span>
        </div>
        <div class="p-l-5">
          <select class="form-control" ref="enabled" @change="onChangeCombo">
            <option value="">{{$t('전체')}}</option>
            <option value="Y">{{$t('사용')}}</option>
            <option value="N">{{$t('사용안함')}}</option>
          </select>
        </div>
      </div>
      <div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px'}">
        <button class="form-control" @click="onSearch">{{$t('조회')}}</button>
        <button class="form-control ml-1 mr-1" @click="onNew">{{$t('신규')}}</button>
        <!-- <button class="form-control" @click="onRemove">삭제</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "../../../api/backEndApi";
import * as rules from "@src/consts/ruleConsts.js";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  props: [],
  components: {

  },
  watch: {

  },
  data() {
    return {
      systemCodeList: null,
      systemCodeName: "",
      rules
    }
  },
  async created() {
    // 처음 진입 시에만 호출
    await this.init();
  },
  mounted() {
  },
  methods: {
    async init() {
      // 대분류코드 목록
      await this.getSystemInfoList();
    },
    async getSystemInfoList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.systeminfo.getSystemInfoList();
        const data = result.data;

        this.systemCodeList = data;
        this.onSearch();
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    // Event
    onChangeCombo() {
      this.onSearch();
    },
    onSearch(){
      console.log(this.$refs);

      var keyword = this.$refs.keyword.value; // 대분류코드
      var enabled = this.$refs.enabled.value; // 사용여부

      var result = this.systemCodeList
        .filter(data => keyword == "" ? data.systemName == data.systemName : data.systemName.includes(keyword) || keyword == "" ? data.systemCode == data.systemCode : data.systemCode.includes(keyword))
        .filter(data => enabled == "" ? data.enabled == data.enabled : data.enabled == enabled);

      this.$emit("search:data", result);
    },
    async reSearch() {
      this.systemCodeList = null;

      await this.getSystemInfoList();
    },
    onNew() {
      this.$emit("new");
    },

  }
};
</script>

<style scoped>

</style>
