<template>
  <div>
    <div class="row-vh d-flex flex-row">
      <div class="w-50">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('계통 코드')}}</span><i class="material-icons ml-1 m-t-1"
              :style="{ fontSize: '15px' }">check_circle</i></div>
          <div class="col-8">
            <valid-input :inputType="'text'" :inputValue.sync="model.systemCode" :readonly = true
              :rules="rules.EQUIP_SYSTEM_CODE_RULE" :classList="'form-control'" :ref="'systemCode'"
              :errorMessage="validMessage.EQUIP_SYSTEM_CODE_VALID_MESSAGE"></valid-input>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0">{{$t('계통 설명')}}</span>
          </div>
          <div class="col-8">
            <!-- <textarea :ref="'systemDetailDsc'" class="form-control" v-model="model.systemDetailDsc" /> -->
            <valid-textarea :ref="'systemDetailDsc'" :classList="'form-control'" :inputType="'text'"
              :inputValue.sync="model.systemDetailDsc" :rules="rules.EQUIP_SYSTEM_DETAIL_RULE"
              :NonStatus="true"></valid-textarea>
          </div>
        </div>
      </div>
      <div class="w-50">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('계통명')}}</span><i class="material-icons ml-1 m-t-1"
              :style="{ fontSize: '15px' }">check_circle</i></div>
          <div class="col-8">
            <!-- <input :ref="'systemName'" class="form-control" v-model="model.systemName" /> -->
            <valid-input :vid="'계통명'" :ref="'systemName'" :inputType="'text'" :classList="'form-control'"
              :inputValue.sync="model.systemName" :rules="rules.EQUIP_SYSTEM_NAME_RULE"
              :errorMessage="validMessage.EQUIP_SYSTEM_NAME_VALID_MESSAGE"></valid-input>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left"><span class="h5">{{$t('사용여부')}}</span><i class="material-icons ml-1 m-t-1"
              :style="{ fontSize: '15px' }">check_circle</i></div>
          <div class="col-8">
            <select class="form-control" :ref="'enabled'" v-model="model.enabled">
              <option value="">{{$t('선택')}}</option>
              <option value="Y">{{$t('사용')}}</option>
              <option value="N">{{$t('사용안함')}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-control-none {
  display: block;
  width: 100%;
  height: calc(1.5em + 14px + 2px);
  padding: 7px 0.75rem;
  font-size: 0.75rem;
  font-weight: 400;
  /* line-height: 1.5; */
  color: #2d353c;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid #d5dbe0;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>

<script>
import backEndApi from "../../../api/backEndApi";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts"
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {},
  props: ["systemCode"],
  watch: {},
  data() {
    return {
      model: {
        systemCode: "",
        systemName: "",
        systemDetailDsc: "",
        enabled: "",
      },
      originModel: {
        systemCode: "",
        systemName: "",
        systemDetailDsc: "",
        enabled: "",
      },
      rules,
      validMessage
    };
  },
  computed: {},
  async created() {
    await this.setModel();
  },
  methods: {
    async setModel() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.systeminfo.getSystemInfo(this.systemCode);
        const data = result.data;

        this.model = {
          systemCode: data.systemCode,
          systemName: data.systemName,
          systemDetailDsc: data.systemDetailDsc,
          enabled: data.enabled,
        };
        this.originModel = {
          systemCode: data.systemCode,
          systemName: data.systemName,
          systemDetailDsc: data.systemDetailDsc,
          enabled: data.enabled,
        };

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }

    },
    async onModify() {
      if (this.isValidation()) {
        let param = {
          systemCode: this.$refs.systemCode.value,
          systemName: this.$refs.systemName.value,
          systemDetailDsc: this.$refs.systemDetailDsc.value,
          enabled: this.$refs.enabled.value,
        };

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
        try {
          await backEndApi.systeminfo.updateSystemInfo(param);
          this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
          this.$emit("reSearch");
        } catch (err) {
          new AppErrorHandler(err)
            .printErrorLog()
            .errHandling();
        }
      }
    },
    isValidation() {
      var flag = true;

      // if (!this.$refs.systemCode.value) {
      //   this.$refs.systemCode.focus();
      //   this.alertNoti(popupMessages.SYSTEM_INFO_CODE_VALID_POPUP_MESSAGE);
      //   flag = false;
      // } else if (!this.$refs.systemName.value) {
      //   this.$refs.systemName.focus();
      //   this.alertNoti(popupMessages.SYSTEM_INFO_NAME_VALID_POPUP_MESSAGE);
      //   flag = false;
      // } else if (!this.$refs.enabled.value) {
      //   this.$refs.enabled.focus();
      //   this.alertNoti(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
      //   flag = false;
      // }
      if (!this.$refs.enabled.value) {
        this.$refs.enabled.focus();
        this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
        flag = false;
      }

      return flag;
    },
    async onRemove() {
      // console.log("Grid detail : onRemove");

      // let param = {
      //   systemCode : this.$refs.systemCode.value,
      // }

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const popupResult = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
        if (!popupResult.value)
          return;

        await backEndApi.systeminfo.deleteSystemInfo(this.$refs.systemCode.value);
        this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
        this.$emit("reSearch");

      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }

    },
    onReset() {
      this.model = {
        systemCode: this.originModel.systemCode,
        systemName: this.originModel.systemName,
        systemDetailDsc: this.originModel.systemDetailDsc,
        enabled: this.originModel.enabled,
      };
    },
  },
};
</script>
