<template>
<vvo v-slot="v">
<div>
  <div class="row-vh d-flex flex-row">
    <div class="w-50">
      <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-4 text-left d-flex">
          <span class="h5 mb-0">{{$t('계통 코드')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">check_circle</i>
        </div>
        <div class="col-8">
          <valid-input :vid="'계통 코드'" :inputType="'text'" :inputValue.sync="model.systemCode"
              :rules="rules.EQUIP_SYSTEM_CODE_RULE" :classList="'form-control'" :ref="'systemCode'"
              :errorMessage="validMessage.EQUIP_SYSTEM_CODE_VALID_MESSAGE"></valid-input>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-4 text-left d-flex">
          <span class="h5 mb-0">{{$t('계통 설명')}}</span>
        </div>
        <div class="col-8">
          <textarea :ref="'systemDetailDsc'" class="form-control" v-model="model.systemDetailDsc" />
        </div>
      </div>
    </div>
    <div class="w-50">
      <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-4 text-left d-flex">
          <span class="h5 mb-0">{{$t('계통명')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">check_circle</i>
        </div>
        <div class="col-8">
          <valid-input :vid="'계통명'" :ref="'systemName'" :inputType="'text'" :classList="'form-control'"
              :inputValue.sync="model.systemName" :rules="rules.EQUIP_SYSTEM_NAME_RULE"
              :errorMessage="validMessage.EQUIP_SYSTEM_NAME_VALID_MESSAGE"></valid-input>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-4 text-left">
          <span class="h5">{{$t('사용여부')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">check_circle</i>
        </div>
        <div class="col-8">
          <select class="form-control" :ref="'enabled'" v-model="model.enabled">
            <option value="">{{$t('선택')}}</option>
            <option value="Y">{{$t('사용')}}</option>
            <option value="N">{{$t('사용안함')}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <new-btn @insert="onValidate(v, onRegist)" @reset="onReset"/>
</div>
</vvo>
</template>

<style scoped>
.form-control-none {
    display: block;
    width: 100%;
    height: calc(1.5em + 14px + 2px);
    padding: 7px 0.75rem;
    font-size: .75rem;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #2d353c;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #d5dbe0;
    border-radius: 4px;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
</style>

<script>
import backEndApi from "../../../api/backEndApi";
import newBtn from "./SystemInfoNewBtn.vue";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts"
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {
    newBtn
  },
  props: [],
  watch: {
    
  },
  data() {
    return {
      model: {
        systemCode : "",
        systemName : "",
        etcInfo1 : "",
        etcInfo2 : "",
        etcInfo3 : "",
        etcInfo4 : "",
        etcInfo5 : "",
        oprtnCodeYn : "",
        systemDetailDsc : "",
        rmk : "",
        enabled : "",
      },
      rules,
      validMessage,
    };
  },
  computed: {
    
  },
  created() {
    
  },
  methods: {
    onReset(){
      this.model= {
        systemCode : null,
        systemName : null,
        systemDetailDsc : null,
        enabled : ""
      };
    },
    async onRegist(){
      // console.log("New modal : onRegist");

      if(this.isValidation()){
        let param = {
          systemCode : this.$refs.systemCode.value,
          systemName : this.$refs.systemName.value,
          systemDetailDsc : this.$refs.systemDetailDsc.value,
          enabled : this.$refs.enabled.value,
        }      

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
        try {
          await backEndApi.systeminfo.insertSystemInfo(param);
          this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
          this.$emit("hide");
          this.$emit("reSearch");

        } catch (err) {
          new AppErrorHandler(err)
            .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
            .errHandling();
        }
      }
    },
    isValidation(){
      var flag = true;

      // if(!this.$refs.systemCode.value){
      //   this.$refs.systemCode.focus();
      //   this.alertNoti(popupMessages.SYSTEM_INFO_CODE_VALID_POPUP_MESSAGE);
      //   flag = false;
      // }else if(!this.$refs.systemName.value){
      //   this.$refs.systemName.focus();
      //   this.alertNoti(popupMessages.SYSTEM_INFO_NAME_VALID_POPUP_MESSAGE);
      //   flag = false;
      // }else if(!this.$refs.enabled.value){
      //   this.$refs.enabled.focus();
      //   this.alertNoti(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
      //   flag = false;
      // }
      if (!this.$refs.enabled.value) {
        this.$refs.enabled.focus();
        this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
        flag = false;
      }

      return flag;
    },
    bindNumber(event){
      let number = event.data;

      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if(reg.exec(number)!== null){
        event.target.value = number.replace(/[^0-9]/g,'');
      } 

       // .... 만 입력하게 될 경우 체크
      if(isNaN(parseFloat(number))){
        event.target.value = "" ;
      } 
    }
  }
};
</script>
